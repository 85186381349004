import { useEffect, useState } from "react";
import Cookies from "js-cookie";

export const API_URL = "https://4sirteameyesonly.zelmira.id/api";
export const BASE_URL = "https://4sirteameyesonly.zelmira.id/";

let MemCache: any = {};

export function imageUrl(path: string) {
  return BASE_URL + path;
}

export function getCookie(name: string) {
  return Cookies.get(name);
}

export default function useContent(
  url: string,
  params: any = {}
): { loading: boolean; data: any } {
  const [state, setState] = useState({
    loading: true,
    data: undefined,
  });

  useEffect(() => {
    const path = encodeURI(`${API_URL}${url}${JSON.stringify(params)}`);
    const isCached = MemCache[path];
    if (isCached) {
      setState({ loading: false, data: isCached });
      return;
    }
    let newParams: any = params;
    setState({ loading: true, data: undefined });
    if (getCookie("lang") && params?.body) {
      newParams.body = JSON.parse(newParams?.body);
      newParams.body.lang = getCookie("lang");
      newParams.body = JSON.stringify(newParams.body);
    }
    fetch(API_URL + url, {
      method: params?.body ? "post" : "get",
      headers: {
        "Content-Type": "application/json",
      },
      ...newParams,
    })
      .then(async (res) => {
        const json = await res.json();
        MemCache[path] = json;
        let newJson = json;
        if (getCookie("lang")) {
          Object.keys(json).forEach(function (key) {
            if (key.slice(-3) === "_" + getCookie("lang")) {
              newJson[key.slice(0, -3)] = json[key];
            }
          });
        }
        setState({ loading: false, data: newJson });
      })
      .catch((err) => {
        setState({ loading: false, data: undefined });
      });
  }, [url]);

  return state;
}
