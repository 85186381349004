import { useRef } from "react";
import { Link } from "react-router-dom";
import { getCookie, imageUrl } from "../useContent";

export default function Product(props: { data: any; types: any }) {
  const d = props.data;
  const ref: any = useRef(null);
  const lang = getCookie("lang");
  let type = d.type.display;
  if (props.types.data?.entries && lang) {
    const selType = (props.types.data?.entries || []).find(
      (v: any) => v._id === d.type._id
    );
    if (selType) {
      type = selType.name_id;
    }
  }
  return (
    <Link
      to={{
        pathname: "/product/" + d._id,
        state: {
          from: window.location.pathname,
        },
      }}
    >
      <div className="mb-40 md:mb-80" ref={ref}>
        <img src={imageUrl(d.gallery[0].path)} width="100%" />
        <div className="mt-20 font-light text-12 md:text-18">{type}</div>
        <div className="font-light text-18 md:text-32">{d.name}</div>
        <div className="font-light text-12 md:text-18">
          {lang ? "Oleh" : "By"}: {d.designer.display}
        </div>
      </div>
    </Link>
  );
}
