import { useCallback, useEffect, useState } from "react";
import { ReactComponent as LargeLeft } from "./svg/left-black.svg";
import { ReactComponent as Close } from "./svg/close.svg";
import { ReactComponent as LargeRight } from "./svg/right-black.svg";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTransition, animated, config } from "react-spring";
import useContent, { getCookie, imageUrl } from "./useContent";

export default function Product() {
  const location: any = useLocation();
  const params: any = useParams();
  const lang = getCookie("lang");
  const history = useHistory();
  const [index, set] = useState(0);

  const content = useContent("/api/collections/get/product", {
    body: JSON.stringify({
      filter: { published: true, _id: params.id },
      limit: 1,
    }),
  });
  useEffect(() => {
    // window.scrollTo(0, 0);
    document
      .getElementsByTagName("body")[0]
      .classList.add("logo-white-menu-white");
  }, []);

  const product: any = content.data?.entries[0];

  const slides: any = product?.gallery || [];
  const transitions = useTransition(slides[index], (item: any) => item, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
  });

  const onClose = () => {
    if (location.state.from) {
      history.goBack();
    } else {
      history.push("/");
    }
  };

  if (content.loading || !content.data) {
    return <div className="min-h-screen" />;
  }

  const onNext = () => {
    set(index + 1 === slides.length ? 0 : index + 1);
  };
  const onPrev = () => {
    set(index === 0 ? slides.lengt - 1 : index - 1);
  };

  return (
    <div
      className="md:fixed w-full z-50 md:flex md:h-screen"
      style={{ backgroundColor: "#fff" }}
    >
      <div className="relative md:w-6/12">
        <button
          onClick={onPrev}
          className="hidden md:block absolute top-50% z-10 -mt-50 left-50"
        >
          <LargeLeft />
        </button>
        {transitions.map(({ item, props, key }: any) => (
          <animated.div
            key={key}
            className="w-full bg-center bg-cover h-product md:h-screen md:absolute"
            style={{
              ...props,
              backgroundImage: `url(${imageUrl(item?.path)})`,
              backgroundSize: "cover",
            }}
          ></animated.div>
        ))}
        <button
          onClick={onNext}
          className="hidden md:block absolute top-50% z-10 -mt-50 right-50"
        >
          <LargeRight />
        </button>
      </div>
      <div className="flex justify-center py-20 md:hidden">
        <div className="flex items-center">
          <button onClick={onPrev}>
            <LargeLeft width={15} />
          </button>
          <div className="font-light text-12 pl-30 pr-30">
            {index + 1}/{slides.length}
          </div>
          <button onClick={onNext}>
            <LargeRight width={15} />
          </button>
        </div>
      </div>
      <div className="relative text-white pt-50 pb-110 md:w-6/12 bg-blue pl-30 pr-30 md:pl-120 md:pr-150 md:pt-120">
        <button
          className="absolute top-0 right-0 z-50 p-10 mr-10 bg-blue mt-30 md:mr-50"
          onClick={onClose}
        >
          <span className="hidden md:block">
            <Close />
          </span>
          <span className="block md:hidden">
            <Close width={15} />
          </span>
        </button>
        <div>
          <div className="font-light text-12 md:text-21">
            {product.category.display}
          </div>
          <div className="font-light text-24 md:text-56">{product.name}</div>
          <div className="font-light text-12 md:text-18">
            {lang ? "Oleh" : "By"}: {product.designer.display}
          </div>
          <div
            className="mt-20 font-thin text-12 md:text-21 md:mt-80"
            dangerouslySetInnerHTML={{ __html: product.description }}
          />
        </div>
      </div>
    </div>
  );
}
