import { ReactComponent as Left } from "./svg/chevron-left.svg";
import { ReactComponent as Right } from "./svg/chevron-right.svg";
import { useEffect, useState } from "react";
import useContent, { imageUrl, getCookie } from "./useContent";
import { useTransition, animated, config } from "react-spring";

export default function Home() {
  const content: any = useContent("/api/singletons/get/page_home", {
    body: JSON.stringify({}),
  });
  const [index, set] = useState(0);
  const slides: any = content.data?.banner || [];
  const lang = getCookie("lang");

  const transitions = useTransition(slides[index], (item: any) => item, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
  });

  useEffect(() => {
    document.getElementsByTagName("body")[0].className =
      "logo-white-menu-white";
  }, []);

  if (content.loading && !content.data) {
    return <div className="min-h-screen" />;
  }

  const onNext = () => {
    set(index + 1 === slides.length ? 0 : index + 1);
  };
  const onPrev = () => {
    set(index === 0 ? slides.lengt - 1 : index - 1);
  };

  return (
    <div>
      <div className="relative flex items-center justify-center bg-center bg-cover h-400 md:h-screen">
        {transitions.map(({ item, props, key }: any) => (
          <animated.div
            key={key}
            className="absolute left-0 right-0 bottom-0 top-0"
            style={{
              ...props,
              backgroundImage: `url(${imageUrl(item?.path)})`,
              backgroundSize: "cover",
            }}
          ></animated.div>
        ))}
        <div
          dangerouslySetInnerHTML={{ __html: content.data.banner_headline }}
          className="relative font-thin text-center text-white z2 text-18 md:text-56"
        />
        {slides.length > 1 && (
          <div className="absolute bottom-0 left-0 flex justify-center w-full mb-50">
            <div className="flex items-center text-white text-21">
              <button onClick={onPrev} className="pr-20">
                <Left />
              </button>
              <div className="font-thin">
                <span className="font-normal">{index + 1}</span>
                <span className="inline-block px-10">/</span>
                <span>{slides.length}</span>
              </div>
              <button onClick={onNext} className="pl-20">
                <Right />
              </button>
            </div>
          </div>
        )}
      </div>
      <section className="md:pt-120">
        <div className="container items-center justify-between md:flex">
          <div className="relative md:z-10 md:-mr-220">
            <div className="pt-40 md:pt-80 pb-120 pl-70 pr-70 md:pl-110 md:pr-110 bg-lightGrey">
              <div className="mb-10 font-thin text-12 md:text-21">
                {content.data.section_1_type}
              </div>
              <div className="mb-20 font-light text-24 md:text-56 md:mb-50">
                {content.data.section_1_title}
              </div>
              <div
                className="font-thin text-12 md:text-21"
                dangerouslySetInnerHTML={{
                  __html: content.data.section_1_content,
                }}
              />
              <a
                href={content.data.section_1_button}
                className="inline-block mt-20 font-thin underline md:mt-60 text-12 md:text-21"
              >
                {lang ? "Jelajahi" : "Explore"}
              </a>
            </div>
          </div>
          <div className="relative w-full md:w-8/12 -mt-80 md:mt-0 pl-30 pr-30 md:pl-0 md:pr-0">
            <img
              src={imageUrl(content.data.section_1_image.path)}
              width="100%"
              {...content.data.section_1_image.meta}
            />
          </div>
        </div>
      </section>
      <section className="relative pb-40 text-white mt-120 md:pb-120">
        <div className="absolute bottom-0 left-0 w-full h-70% bg-blue" />
        <div className="container relative">
          <img
            src={imageUrl(content.data.section_2_image.path)}
            {...content.data.section_2_image.meta}
            width="100%"
            alt="banner 2"
          />
          <div className="flex justify-center mt-40 md:mt-50">
            <div>
              <div className="hidden mb-40 font-light md:block text-56">
                {content.data.section_2_title}
              </div>
              <div
                className="font-light text-12 md:text-21 md:mb-60 md:ml-110"
                dangerouslySetInnerHTML={{
                  __html: content.data.section_2_content,
                }}
              />
              <a
                href={content.data.section_2_button}
                className="inline-block font-light underline mt-30 md:mt-0 text-12 md:text-21 md:ml-110"
              >
                {lang ? "Jelajahi" : "Explore"}
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-40 pb-40 md:pt-120 md:pb-120">
        <div className="mb-40 font-light text-center md:mb-120 text-24 md:text-56">
          {content.data.section_3_title}
        </div>
        <div className="container items-center justify-between md:flex">
          <div className="w-full md:w-8/12 pl-30 pr-30 md:pl-0 md:pr-0">
            <img
              src={imageUrl(content.data.section_3_image.path)}
              {...content.data.section_3_image.meta}
              width="100%"
              alt="banner 2"
            />
          </div>
          <div className="relative w-8/12 ml-auto mr-auto md:w-auto -mt-50 md:mt-0 md:-ml-50">
            <div className="py-20 md:pt-60 md:pb-60 md:pl-110 px-30 md:pr-110 bg-lightGrey">
              <div className="mb-10 font-light md:mb-32 text-12 md:text-21">
                {content.data.section_3_type}
              </div>
              <div
                className="font-thin text-12 md:text-21"
                dangerouslySetInnerHTML={{
                  __html: content.data.section_3_content,
                }}
              />
              <a
                href={content.data.section_3_button}
                className="inline-block mt-20 font-thin underline md:mt-40 text-12 md:text-21"
              >
                {lang ? "Selebihnya" : "More"}
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
