import { Link, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ReactComponent as Down } from "./svg/arrow-down.svg";
import useContent, { getCookie, imageUrl } from "./useContent";
import { ReactComponent as Right } from "./svg/chevron-right-black.svg";
import { ReactComponent as LargeLeft } from "./svg/left-black.svg";
import { ReactComponent as LargeRight } from "./svg/right-black.svg";
import Product from "./components/Product";

function DropDown(props: {
  filtered: string;
  setFiltered: Function;
  currentCollection: any;
  types: any;
}) {
  const [show, setShow] = useState(false);
  const lang = getCookie("lang");
  const content = props.types;

  useEffect(() => {
    // window.scrollTo(0, 0);
    if (show) {
      document.getElementsByTagName("body")[0].classList.add("overflow-hidden");
    } else {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("overflow-hidden");
    }
    return () => {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("overflow-hidden");
    };
  }, [show]);

  if (content.loading || !content.data) {
    return null;
  }

  return (
    <>
      <div
        className={`flex items-center font-light ${
          props.currentCollection ? "icon-down-light" : ""
        }`}
      >
        <span className="hidden md:inline-block">
          {lang ? "Lihat" : "View"}
        </span>{" "}
        <div
          className={`flex items-center relative ${
            show ? "text-white z-50" : "z-0"
          }`}
        >
          <button
            onClick={() => setShow(!show)}
            className={`flex items-center ml-10 mr-10 font-light underline ${
              props.currentCollection ? "text-white" : ""
            }`}
          >
            <span className="hidden md:inline-block">{props.filtered}</span>
            <span className="md:hidden text-18">Filter</span>
          </button>{" "}
          <Down className={show ? "down-light down" : "down"} />
          {show && (
            <div className="absolute top-0 left-10 mt-60">
              {[
                ...(content.data?.entries || []),
                { name: "Everything", name_id: "Semua" },
              ].map((d: any, i: number) => {
                if ([d.name, d.name_id].includes(props.filtered)) return null;
                return (
                  <button
                    onClick={() => {
                      props.setFiltered(lang ? d.name_id : d.name);
                      setShow(false);
                    }}
                    key={i}
                    className="block font-light whitespace-nowrap text-32"
                  >
                    {lang ? d.name_id : d.name}
                  </button>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {show && (
        <div className="fixed z-40 top-0 bottom-0 left-0 right-0 bg-black bg-opacity-90" />
      )}
    </>
  );
}

export default function AllCollection() {
  const params: any = useParams();
  const lang = getCookie("lang");

  const [filtered, setFiltered] = useState(lang ? "Semua" : "Everything");
  const types = useContent("/api/collections/get/product_type", {
    body: JSON.stringify({}),
  });
  const location = useLocation();
  const content = useContent(
    "/api/collections/get/product",
    params?.id
      ? {
          body: JSON.stringify({
            filter: { published: true, "category._id": params.id },
          }),
        }
      : undefined
  );
  const collections = useContent("/api/collections/get/product_collections");

  const currentCollection = (collections.data?.entries || []).find(
    (d: any) => d._id === params.id
  );

  useEffect(() => {
    document.getElementsByTagName("body")[0].className = currentCollection
      ? "logo-white-menu-white"
      : "logo-dark-menu-dark";
  }, [params.id, currentCollection]);

  if (content.loading || !content.data) {
    return <div className="min-h-screen" />;
  }

  return (
    <div>
      <div
        className={`flex items-center justify-center md:h-screen ${
          currentCollection ? "bg-blue text-white" : "bg-lightGrey"
        }`}
      >
        <div className="font-light text-center pt-150 pb-220 md:-mt-220 text-18 md:text-56">
          {currentCollection?.name || lang ? "Semua Koleksi" : "All Collection"}
        </div>
      </div>
      <div className="relative pl-30 pr-30 -mt-150 md:pl-0 md:pr-0 md:-mt-40vh">
        <div
          className={`container mb-20 font-light md:mb-50 ${
            currentCollection ? "text-white" : "text-dark"
          } text-32`}
        >
          <DropDown
            filtered={filtered}
            setFiltered={setFiltered}
            currentCollection={currentCollection}
            types={types}
          />
        </div>
        <div className="container grid grid-cols-2 md:grid-cols-3 gap-20">
          {(content.data?.entries || [])
            .filter((d: any) =>
              !["Semua", "Everything"].includes(filtered)
                ? d?.type?.display === filtered
                : true
            )
            .map((d: any, i: number) => (
              <Product key={i} data={d} types={types} />
            ))}
        </div>
        {collections.data?.entries.length > 1 && (
          <div className="hidden bg-lightGrey md:block pt-60 pb-60">
            <div className="container mb-20">
              <div className="font-light text-center mb-60 text-56">
                Other Collection
              </div>
              <div className="relative grid grid-cols-3 gap-20">
                {collections.data?.entries.length > 3 && (
                  <button className="absolute -left-80 top-50%">
                    <LargeLeft />
                  </button>
                )}
                {(collections.data?.entries || []).map((d: any, i: number) => (
                  <div key={i}>
                    <img src={imageUrl(d.thumbnail.path)} width="100%" />
                    <div className="mt-20">
                      <Link className="flex items-center text-18" to="/">
                        {d.name} <Right width={20} className="pl-10" />
                      </Link>
                    </div>
                  </div>
                ))}

                {collections.data?.entries.length > 3 && (
                  <button className="absolute top-50% -right-80">
                    <LargeRight />
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
