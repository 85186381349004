import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  createContext,
  lazy,
  Suspense,
  useReducer,
  useContext,
  useEffect,
} from "react";
import MainLayout from "./components/layout/MainLayout";
import About from "./About";
import Press from "./Press";
import Contact from "./Contact";
import Collection from "./Collections";
import AllCollection from "./AllCollection";
import Product from "./Product";
import Home from "./Home";

type Action =
  | { type: "SET"; key: string; value: any }
  | { type: "PATCH"; value: any };
type Dispatch = (action: Action) => void;
type State = { headerTheme: string; menuTheme: string };

const CtxStateContext = createContext<State | undefined>(undefined);
const CtxDispatchContext = createContext<Dispatch | undefined>(undefined);

function ctxReducer(state: State, action: Action) {
  switch (action.type) {
    case "SET": {
      return { ...state, [action.key]: action.value };
    }
    case "PATCH": {
      return { ...state, ...action.value };
    }
    default: {
      throw new Error("Unhandled action type");
    }
  }
}

export function useCtxState() {
  const context = useContext(CtxStateContext);
  if (context === undefined) {
    throw new Error("useCountState must be used within a CountProvider");
  }
  return context;
}

export function useCtxDispatch() {
  const context = useContext(CtxDispatchContext);
  if (context === undefined) {
    throw new Error("useCountDispatch must be used within a CountProvider");
  }
  return context;
}

function NoMatch() {
  useEffect(() => {
    document.getElementsByTagName("body")[0].className = "logo-dark-menu-dark";
  }, []);
  return (
    <div className="bg-lightGrey min-h-screen text-dark pl-20 md:pl-60">
      <div className="font-light text-32 pt-150">404</div>
      <div className="font-light text-56 pt-10 mb-150">
        No webpage was found
      </div>
      <Link
        className="text-32 mb-50 font-light pt-150 inline-block border-b"
        to="/"
      >
        Back to Home
      </Link>
    </div>
  );
}

function App() {
  const [state, dispatch] = useReducer(ctxReducer, {
    menuTheme: "light",
    headerTheme: "light",
  });
  useEffect(() => {
    var counter = 0;
    var c = 0;
    var divcounter: any = document.getElementById("html-loading-bar");
    if (!divcounter) return;
    var i = setInterval(function () {
      divcounter.style.width = c + "%";
      counter++;
      c++;
      if (counter == 101) {
        clearInterval(i);
        const loading: any = document.getElementById("initial-loading");
        loading.classList.add("hide-loader");
      }
    }, 20);
  }, []);
  return (
    <Suspense fallback={null}>
      <Router>
        <CtxStateContext.Provider value={state}>
          <CtxDispatchContext.Provider value={dispatch}>
            <MainLayout>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/press" component={Press} />
                <Route exact path="/contact" component={Contact} />
                <Route path="/all-collections/:id?" component={AllCollection} />
                <Route exact path="/product/:id" component={Product} />
                <Route exact path="/collections/:id" component={Collection} />
                <Route exact path="/about" component={About} />
                <Route path="*" component={NoMatch} />
              </Switch>
            </MainLayout>
          </CtxDispatchContext.Provider>
        </CtxStateContext.Provider>
      </Router>
    </Suspense>
  );
}

export default App;
