import { imageUrl } from "../useContent";
import { ReactComponent as Right } from "../svg/chevron-right-black.svg";
import { ReactComponent as LargeLeft } from "../svg/left-black.svg";
import { ReactComponent as LargeRight } from "../svg/right-black.svg";
import { Link } from "react-router-dom";

export default function CarouselCollection(props: { data: any }) {
  const { data } = props;
  return (
    <div className="relative grid grid-cols-3 gap-20">
      {data?.entries.length > 3 && (
        <button className="absolute -left-80 top-50%">
          <LargeLeft />
        </button>
      )}
      {(data?.entries || []).map((d: any, i: number) => (
        <div key={i}>
          <img src={imageUrl(d.thumbnail.path)} width="100%" />
          <div className="mt-20">
            <Link className="flex items-center text-18" to="/">
              {d.name} <Right width={20} className="pl-10" />
            </Link>
          </div>
        </div>
      ))}

      {data?.entries.length > 3 && (
        <button className="absolute top-50% -right-80">
          <LargeRight />
        </button>
      )}
    </div>
  );
}
